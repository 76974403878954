/*!
 * 
 *  Custom Scripts
 * 
 */

//===================================================================		
//  Push the footer to bottom of page
//===================================================================

/*jQuery(document).ready(function( $ ) {
   var docHeight = $(window).height();
   var footerHeight = $('#footer').outerHeight();
   var footerTop = $('#footer').position().top + footerHeight;

   if (footerTop < docHeight) {
    $('#footer').css('margin-top', 10+ (docHeight - footerTop) + 'px');
   }
  });*/

//===================================================================		
// END of Push the footer to bottom of page
//===================================================================	

	
//===================================================================		
//  Start Cookie Bar
//===================================================================
jQuery(document).ready(function( $ ) {
$.cookieBar({
message:'We use cookies to give you the best online experience. By using this website you agree with our cookie policy.',
fixed: true,
acceptOnScroll: 300,
bottom: true,
effect: 'slide',
zindex: '5001',
policyButton: false,
policyText: 'Read more',
//policyURL: ''
policyURL: ''

});
});
//===================================================================		
// END of Cookie Bar
//===================================================================


//===================================================================		
//  SCROLL To Top
//===================================================================

// SCROLL to TOP
jQuery(document).ready(function( $ ) {
	
	//Check to see if the window is top if not then display button
	$(window).scroll(function(){
		if ($(this).scrollTop() >= 1200) {		// If page is scrolled more than 50px
			$('.scrollToTop').fadeIn(800);		// Fade in the arrow
		} else {
			$('.scrollToTop').fadeOut(800);		// Else fade out the arrow
		}
	});
	
	//Click event to scroll to top
	$('.scrollToTop').click(function(){					// When arrow is clicked
		$('html, body').animate({scrollTop : 0},800); 	// Scroll to top of body
		return false;
	});
	
});

//===================================================================		
// END of Comment
//===================================================================







/*----------------------------------------------------------- 
/* Sticky & Shrinking Header
---------------------------------------------------------- */

    //caches a jQuery object containing the header element
    var header = jQuery(".site-header");
	
    jQuery(window).scroll(function() {
        var scroll = jQuery(window).scrollTop();

        if (scroll >= 50) {
         //   jQuery(".site-header").removeClass("no-shrink");
            jQuery(".site-header").addClass("shrink");

        } else {
            jQuery(".site-header").removeClass("shrink");
            //jQuery(".site-header").addClass("no-shrink");
		}
		
		});	


/*----------------------------------------------------------- 
 * End Sticky & Shrinking Header
---------------------------------------------------------- */
